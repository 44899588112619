
import { defineComponent } from "vue";
import CodeHighlight from "vue-code-highlight/src/CodeHighlight.vue";
import "vue-code-highlight/themes/duotone-sea.css";
import "vue-code-highlight/themes/window.css";

export default defineComponent({
  components: {
    CodeHighlight,
  },
  props: {
    language: String,
    text: String,
  },
  data() {
    return {
      renderComponent: true,
    };
  },
  methods: {
    async rerender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;
    },
  },
  watch: {
    language: {
      handler(newVal, oldVal) {
        this.rerender().then().catch();
      },
    },
    text: {
      handler(newVal, oldVal) {
        this.rerender().then().catch();
      },
    },
  },
});
