import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTransitionStart = _resolveComponent("PageTransitionStart")!
  const _component_PageTransitionEnd = _resolveComponent("PageTransitionEnd")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.pageLoadingStore.isLoading && _ctx.pageLoadingStore.startLoading)
      ? (_openBlock(), _createBlock(_component_PageTransitionStart, { key: 0 }))
      : (_ctx.pageLoadingStore.isLoading && !_ctx.pageLoadingStore.startLoading)
        ? (_openBlock(), _createBlock(_component_PageTransitionEnd, { key: 1 }))
        : _createCommentVNode("", true),
    _createVNode(_component_Layout, null, {
      default: _withCtx(() => [
        _createVNode(_component_RouterView, { class: "pt-52 lg:pt-32 xl:px-28" })
      ]),
      _: 1
    })
  ], 64))
}