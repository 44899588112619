
import { defineComponent } from "vue";
import {Language} from "../../../shared/Language";
import {PostT} from "../../../shared/post/PostT";
import {PostsAPI} from "@/api/PostsAPI";
import {useAppStore} from "@/stores/AppStore";
import NewsItem from "@/components/news/NewsItem.vue";

export default defineComponent({
  name: "NewsView",
  components: {NewsItem},
  setup() {
    const appStore = useAppStore();
    const postsAPI = new PostsAPI();
    return { appStore, postsAPI };
  },
  async created() {
    this.language = this.appStore.selectedLanguage;
    try {
      this.posts = await this.postsAPI.getAllPreviews(this.language);
    } catch (error: any) {
      this.posts = [];
    }
  },
  computed: {
    selectedLanguageForWatch(): Language {
      return this.appStore.selectedLanguage;
    },
  },
  watch: {
    async selectedLanguageForWatch(newLanguage) {
      this.posts = await this.postsAPI.getAllPreviews(newLanguage);
      this.language = newLanguage;
    },
  },
  data(): {
    posts: PostT[];
    language: Language;
  } {
    return {
      posts: [],
      language: Language.ENGLISH,
    };
  }
});
