import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "h-full w-full bg-primarySecond" }
const _hoisted_2 = { class: "container mx-auto h-full w-full" }
const _hoisted_3 = { class: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-10 gap-y-24 max-h-[calc(100vh-18rem)] xl:max-h-[calc(100vh-8rem)] overflow-y-scroll overflow-x-hidden scrollbar-none pb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_news_item = _resolveComponent("news-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts, (post, index) => {
          return (_openBlock(), _createBlock(_component_news_item, {
            key: post._id,
            "is-main": index === 0,
            post: post,
            language: _ctx.language
          }, null, 8, ["is-main", "post", "language"]))
        }), 128))
      ])
    ])
  ]))
}