import { defineStore } from "pinia";

export enum PageLoadingType {
  START = "START",
  END = "END",
}

export const usePageLoadingStore = defineStore("pageLoading", {
  state: () => {
    return {
      isLoading: false,
      startLoading: false,
    };
  },
  actions: {
    enableLoading() {
      this.isLoading = true;
    },
    disableLoading() {
      this.isLoading = false;
    },
    setLoadingType(type: PageLoadingType) {
      this.startLoading = type === PageLoadingType.START;
    },
  },
});
