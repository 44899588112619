
import { defineComponent } from "vue";
import { Motion } from "motion/vue";
import LogoIcon from "@/components/icons/LogoIcon.vue";

export default defineComponent({
  name: "PageTransitionStart",
  components: { LogoIcon, Motion },
  data() {
    return {
      transitionVariants: {
        initial: {
          height: "0%",
        },
        animate: {
          height: ["0%", "100%"],
        },
      },
    };
  },
});
