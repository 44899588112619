
import { defineComponent } from "vue";
import LoginView from "@/views/LoginView.vue";

export default defineComponent({
  name: "RegisterView",
  computed: {
    LoginView() {
      return LoginView
    }
  },
  data(): {
    email: string;
    password: string;
    confirmPassword: string;
  } {
    return {
      email: "",
      password: "",
      confirmPassword: "",
    };
  },
  methods: {
    async register() {
      // await this.$store.dispatch('login', {email: this.email, password: this.password})
    },
  },
});
