
import { defineComponent } from "vue";
import ProjectsButton from "@/components/ProjectsButton.vue";
import Avatar from "@/components/Avatar.vue";
import {useUserStore} from "@/stores/UserStore";

export default defineComponent({
  name: "HomeView",
  components: { Avatar, ProjectsButton },
  setup() {
    const userStore = useUserStore();

    return { userStore };
  },
  data(){
    return {
      subIntroText: 'Let me introduce the blog of a passionate and innovative software engineer who is in search of technologies, frameworks, elegant solutions to complex problems, or just anything for fun. So welcome aboard and let\'s go explore it together!'
    }
  }
});
