import { defineStore } from "pinia";
import type { UserDTO } from "../../../shared/user/UserDTO";
import { UsersAPI } from "@/api/UsersAPI";

export type UserState = {
  user: UserDTO | null;
};

const userAPI = new UsersAPI();
export const useUserStore = defineStore("user", {
  state: (): UserState => {
    return {
      user: null,
    };
  },
  getters: {
    isCurrentUserAdmin: (state: UserState): boolean => {
      return Boolean(state.user?.admin);
    },
  },
  actions: {
    async getCurrentUser() {
      try {
        this.user = await userAPI.getCurrentUser();
      } catch (error: any) {
        console.log("getCurrentUser error ", error.message);
        this.user = null;
      }
    },
    logout() {
      this.user = null;
      localStorage.removeItem("token");
    },
  },
});
