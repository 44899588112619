import axios, {
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
} from "axios";

export class HttpClient {
  protected endPoint: string = "";
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    this.httpClient.interceptors.request.use((config) => {
      config.headers["Authorization"] = this.getAuthToken();
      return config;
    });
  }

  public post<ResponseDataT, DataT, ResponseT = AxiosResponse<ResponseDataT>>(
    url: string,
    data?: DataT,
    config?: AxiosRequestConfig<DataT>
  ): Promise<ResponseT> {
    return this.httpClient.post(`${this.endPoint}${url}`, data, config);
  }

  public put<ResponseDataT, DataT, ResponseT = AxiosResponse<ResponseDataT>>(
    url: string,
    data?: DataT,
    config?: AxiosRequestConfig<DataT>
  ): Promise<ResponseT> {
    return this.httpClient.put(`${this.endPoint}${url}`, data, config);
  }

  public get<
    ResponseDataT,
    DataT = null,
    ResponseT = AxiosResponse<ResponseDataT>
  >(url: string, config?: AxiosRequestConfig<DataT>): Promise<ResponseT> {
    return this.httpClient.get(`${this.endPoint}${url}`, config);
  }

  public delete<
    ResponseDataT,
    DataT = null,
    ResponseT = AxiosResponse<ResponseDataT>
  >(url: string, config?: AxiosRequestConfig<DataT>): Promise<ResponseT> {
    return this.httpClient.get(`${this.endPoint}${url}`, config);
  }

  private getAuthToken() {
    return "Bearer " + localStorage.getItem("token");
  }
}
