import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-93f5c4e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height", "fill"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(`${_ctx.startAnimation ? 'logo-svg-animation-start' : ''} ${
      _ctx.endAnimation ? 'logo-svg-animation-end' : ''
    }`),
    width: _ctx.width ? _ctx.width : '24px',
    height: _ctx.height ? _ctx.height : '24px',
    fill: _ctx.fill ?? 'currentColor',
    viewBox: "0 0 512 512"
  }, [
    _createElementVNode("path", {
      class: _normalizeClass(`${_ctx.startAnimation ? 'logo-circle-animation-start' : ''} ${
        _ctx.endAnimation ? 'logo-circle-animation-end' : ''
      }`),
      fill: _ctx.fill ?? 'currentColor',
      d: "M511.17,251.72c-5.73,335.66-505.01,335.6-510.7-.01,5.73-335.64,505.01-335.59,510.7,.01Z"
    }, null, 10, _hoisted_2),
    _createElementVNode("path", {
      class: _normalizeClass(`strokes ${_ctx.startAnimation ? 'strokes-animation-start' : ''} ${
        _ctx.endAnimation ? 'strokes-animation-end' : ''
      }`),
      d: "M.15,252.52c47.78,.44,55.55,1.01,82.08-6.08,33.98-9.08,49.47-59.06,50.03-60.56,.56-1.5,24.66,3.5,46.52-3.5,21.86-7.01,35.31-13.05,65.02-54.59,29.71-41.55-44.28,227.29-107.06,245.31-38.16,10.95-59.56-47.72-54.52-54.23,5.04-6.51,74.7-3.8,103.14-7.64,34.4-4.64,83.36-12.65,83.36-12.65"
    }, null, 2),
    _createElementVNode("path", {
      class: _normalizeClass(`strokes ${_ctx.startAnimation ? 'strokes-animation-start' : ''} ${
        _ctx.endAnimation ? 'strokes-animation-end' : ''
      }`),
      d: "M399.26,179.78c19.32-43.74-10.53-65.77-51.57-52.28,0,0-29.71,10.15-51.01,67-23.54,78.66-43.4,140.82-14.58,168.01,71.08,45.54,153.14-30.75,227.01-62.95"
    }, null, 2)
  ], 10, _hoisted_1))
}