import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center gap-x-5" }
const _hoisted_2 = { href: "https://www.linkedin.com/in/ihor-chernukha-424a40178" }
const _hoisted_3 = { class: "group-hover:opacity-100 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2 -translate-x-1/2 translate-y-full opacity-0 m-2 mx-auto" }
const _hoisted_4 = { href: "https://www.facebook.com/igor.chernuha.1" }
const _hoisted_5 = {
  href: "https://www.instagram.com/chernuhai/",
  target: "_blank",
  rel: "noopener noreferrer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconLinkedIn = _resolveComponent("IconLinkedIn")!
  const _component_IconGoogle = _resolveComponent("IconGoogle")!
  const _component_IconFacebook = _resolveComponent("IconFacebook")!
  const _component_IconInstagram = _resolveComponent("IconInstagram")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", _hoisted_2, [
      _createVNode(_component_IconLinkedIn)
    ]),
    _createElementVNode("button", {
      class: "group flex relative",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyGmail()))
    }, [
      _createVNode(_component_IconGoogle),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.gmailCopied ? 'Copied' : _ctx.gmail), 1)
    ]),
    _createElementVNode("a", _hoisted_4, [
      _createVNode(_component_IconFacebook)
    ]),
    _createElementVNode("a", _hoisted_5, [
      _createVNode(_component_IconInstagram)
    ])
  ]))
}