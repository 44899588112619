
import { defineComponent } from "vue";
import { usePageLoadingStore } from "@/stores/PageLoadingStore";
import Layout from "@/components/base-page/Layout.vue";
import PageTransitionStart from "@/components/page-transition/PageTransitionStart.vue";
import PageTransitionEnd from "@/components/page-transition/PageTransitionEnd.vue";
import { useUserStore } from "@/stores/UserStore";
import { useAppStore } from "@/stores/AppStore";

export default defineComponent({
  components: { PageTransitionEnd, PageTransitionStart, Layout },
  setup() {
    const pageLoadingStore = usePageLoadingStore();
    const userStore = useUserStore();
    const appStore = useAppStore();

    return { pageLoadingStore, userStore, appStore };
  },
  async created() {
    this.appStore.userLoading();
    await this.userStore.getCurrentUser();
    this.appStore.userLoaded();
  },
});
