<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    viewBox="0 0 612 612"
    fill="currentColor"
    xml:space="preserve"
  >
    <g>
      <g id="_x37__40_">
        <g>
          <path
            d="M439.875,153h153c10.557,0,19.125-8.568,19.125-19.125s-8.568-19.125-19.125-19.125h-153
				c-10.557,0-19.125,8.568-19.125,19.125S429.318,153,439.875,153z M592.875,459h-114.75C467.568,459,459,467.568,459,478.125
				s8.568,19.125,19.125,19.125h114.75c10.557,0,19.125-8.568,19.125-19.125S603.432,459,592.875,459z M592.875,229.5h-153
				c-10.557,0-19.125,8.568-19.125,19.125s8.568,19.125,19.125,19.125h153c10.557,0,19.125-8.568,19.125-19.125
				S603.432,229.5,592.875,229.5z M592.875,344.25h-114.75c-10.557,0-19.125,8.568-19.125,19.125s8.568,19.125,19.125,19.125h114.75
				c10.557,0,19.125-8.568,19.125-19.125S603.432,344.25,592.875,344.25z M376.82,331.226
				c-38.461,54.354-98.628,89.524-166.445,89.524S82.391,385.579,43.93,331.226C17.289,352.11,0,384.24,0,420.75v76.5
				C0,560.63,51.37,612,114.75,612H306c63.38,0,114.75-51.37,114.75-114.75v-76.5C420.75,384.24,403.461,352.11,376.82,331.226z
				 M210.375,382.5c95.07,0,172.125-85.623,172.125-191.25S305.445,0,210.375,0S38.25,85.623,38.25,191.25
				S115.305,382.5,210.375,382.5z"
          />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
