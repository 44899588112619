import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full mt-4" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("iframe", {
        class: "m-auto",
        style: _normalizeStyle({
          ...(_ctx.width ? { width: _ctx.width } : { width: '800px' }),
          ...(_ctx.height ? { height: _ctx.height } : { height: '400px' }),
        }),
        src: _ctx.url,
        frameborder: "0",
        allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
        allowfullscreen: ""
      }, "\n      ", 12, _hoisted_3)
    ])
  ]))
}