
import {defineComponent} from 'vue'
import IconGoogle from "@/components/icons/IconGoogle.vue";

export default defineComponent({
  name: "LoginView",
  components: { IconGoogle },
  props: ['skipPageLoading'],
  data(): {
    email: string;
    password: string;
    googleAuthLink: string;
  } {
    return {
      email: "",
      password: "",
      googleAuthLink: `${process.env.VUE_APP_API_URL}/auth/google`,
    };
  },
  methods: {
    async login() {
      // await this.$store.dispatch('login', {email: this.email, password: this.password})
    },
  },
})
