import { createRouter, createWebHistory } from "vue-router";
import { Links } from "@/router/Links";
import {
  PageLoadingType,
  usePageLoadingStore,
} from "@/stores/PageLoadingStore";

const linksForLoading = Links.filter(
  (linkData) => linkData.isNav || ["login", "account"].includes(linkData.name)
).map((linkData) => {
  return linkData.path;
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: Links.map((linkData) => {
    return {
      path: linkData.path,
      name: linkData.name,
      component: linkData.component,
    };
  }),
});

router.beforeEach((to, from, next) => {
  const isSkipLoadingByRouterParam = to.query.SPL;
  if (!isSkipLoadingByRouterParam && linksForLoading.includes(to.path)) {
    const pageLoadingStore = usePageLoadingStore();
    pageLoadingStore.enableLoading();
    pageLoadingStore.setLoadingType(PageLoadingType.START);
    setTimeout(() => {
      next();
    }, 1600);
  } else {
    next();
  }
});

router.afterEach((to) => {
  const isSkipLoadingByRouterParam = to.query.SPL;
  const pageLoadingStore = usePageLoadingStore();
  if (!isSkipLoadingByRouterParam && linksForLoading.includes(to.path)) {
    pageLoadingStore.setLoadingType(PageLoadingType.END);
    setTimeout(() => {
      pageLoadingStore.disableLoading();
    }, 1600);
  } else {
    pageLoadingStore.disableLoading();
  }
});

export default router;
