
import { defineComponent } from "vue";
import { Links } from "@/router/Links";
import { usePageLoadingStore } from "@/stores/PageLoadingStore";
import { useUserStore } from "@/stores/UserStore";

export default defineComponent({
  name: "Nav",
  setup() {
    const pageLoadingStore = usePageLoadingStore();
    const userStore = useUserStore();
    return { pageLoadingStore, userStore };
  },
  methods: {
    Links() {
      return Links.filter(
        (link) =>
          link.isNav ||
          (this.userStore.user && link.name === "account") ||
          (!this.userStore.user && link.name === "login")
      ).map((link) => {
        return {
          name: link.name,
          icon: link.icon,
          path: link.path,
        };
      });
    },
  },
});
