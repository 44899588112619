
import {defineComponent} from "vue";
import IconLinkedIn from "@/components/icons/IconLinkedIn.vue";
import IconGoogle from "@/components/icons/IconGoogle.vue";
import IconFacebook from "@/components/icons/IconFacebook.vue";
import IconInstagram from "@/components/icons/IconInstagram.vue";

export default defineComponent({
  name: "Socials",
  components: { IconInstagram, IconFacebook, IconGoogle, IconLinkedIn },
  data() {
    return {
      gmail: "chernuha9544@gmail.com",
      gmailCopied: false
    };
  },
  methods: {
    copyGmail(){
      navigator.clipboard.writeText(this.gmail)
      this.gmailCopied = true;
      setTimeout(()=> {
        this.gmailCopied = false
      }, 1500)
    }
  }
});
