
import { defineComponent } from "vue";
import RoundTextSymbol from "@/components/RoundTextSymbol.vue";
import IconHandPoint from "@/components/icons/IconHandPoint.vue";

export default defineComponent({
  name: "ProjectsButton",
  components: { IconHandPoint, RoundTextSymbol },
  data() {
    return {
      textSymbols: "My experience  .  My experience  .  ".split(""),
      component: RoundTextSymbol,
    };
  },
  methods: {
    goToAbout(){
      this.$router.push('/about');
    }
  }
});
