import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5cce9414"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mx-auto xl:mx-0" }
const _hoisted_2 = { class: "relative w-[186px] h-[186px] flex justify-center items-center bg-circleStar bg-cover bg-center bg-no-repeat" }
const _hoisted_3 = {
  id: "round-text",
  class: "relative w-[186px] h-[186px]"
}
const _hoisted_4 = { class: "absolute" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_hand_point = _resolveComponent("icon-hand-point")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.textSymbols, (symbol, index) => {
          return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), _mergeProps({ symbol, turnDeg: 10.1 * index }, { key: index }), null, 16))
        }), 128))
      ]),
      _createElementVNode("button", _hoisted_4, [
        _createVNode(_component_icon_hand_point, {
          class: "w-[34px] h-[34px]",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToAbout()))
        })
      ])
    ])
  ]))
}