
import { defineComponent, PropType } from "vue";
import TextBlock from "@/components/news/post/TextBlock.vue";
import ImageBlock from "@/components/news/post/ImageBlock.vue";
import CodeBlock from "@/components/news/post/CodeBlock.vue";
import YoutubeBlock from "@/components/news/post/YoutubeBlock.vue";
import { useAppStore } from "@/stores/AppStore";
import { PostsAPI } from "@/api/PostsAPI";
import { CreatePostViewT } from "@/types/CreatePostViewT";
import { PostViewBlockT, PostViewT } from "@/types/PostViewT";
import { Utils } from "@/utils/Utils";
import { PostBodyT, PostLanguageT, PostT } from "../../../shared/post/PostT";
import { PostBlockType } from "../../../shared/post/PostBlockType";
import { TextT } from "../../../shared/post/blocks/TextT";
import { ImageT } from "../../../shared/post/blocks/ImageT";
import { CodeT } from "../../../shared/post/blocks/CodeT";
import { YoutubeT } from "../../../shared/post/blocks/YoutubeT";
import { Language } from "../../../shared/Language";

export default defineComponent({
  name: "PostView",
  components: { YoutubeBlock, CodeBlock, ImageBlock, TextBlock },
  setup() {
    const appStore = useAppStore();
    const postsAPI = new PostsAPI();
    return {
      appStore,
      postsAPI,
    };
  },
  props: {
    post: Object as PropType<CreatePostViewT>,
  },
  async created() {
    if (this.post) {
      this.fullPost = this.post;
      this.postData = this.post.language[
        this.appStore.selectedLanguage
      ] as PostViewT;
    } else {
      const postId = this.$route.params.id as string;
      if (postId) {
        this.fullPost = await this.postsAPI.getPostById(postId);
        if (this.fullPost) {
          this.postData = this.madeBlocksAsCorrectComponent(this.fullPost);
        }
      }
    }
  },
  computed: {
    Utils() {
      return Utils;
    },
    selectedLanguageForWatch(): Language {
      return this.appStore.selectedLanguage;
    },
  },
  watch: {
    async selectedLanguageForWatch() {
      if (!this.post) {
        this.postData = this.madeBlocksAsCorrectComponent(
          this.fullPost as PostT
        );
      }
    },
  },
  data(): {
    fullPost: PostT | CreatePostViewT;
    postData: PostViewT;
  } {
    return {
      fullPost: {
        _id: "",
        language: {} as PostLanguageT,
      },
      postData: {
        title: "",
        blocks: [],
        createdAt: new Date(),
      },
    };
  },
  methods: {
    madeBlocksAsCorrectComponent(post: PostT): PostViewT {
      if (post.language[this.appStore.selectedLanguage]) {
        const postBody = post.language[
          this.appStore.selectedLanguage
        ] as PostBodyT;

        return {
          title: postBody.title,
          createdAt: postBody.createdAt,
          blocks: postBody.blocks.map((block) => {
            let postViewBlock: PostViewBlockT;
            switch (block.type) {
              case PostBlockType.TEXT: {
                const textBlock = block as TextT;
                postViewBlock = {
                  component: TextBlock,
                  data: {
                    type: textBlock.type,
                    position: textBlock.position,
                    text: textBlock.text,
                  },
                };
                break;
              }
              case PostBlockType.IMAGE: {
                const imageBlock = block as ImageT;
                postViewBlock = {
                  component: ImageBlock,
                  data: {
                    type: imageBlock.type,
                    position: imageBlock.position,
                    src: imageBlock.src,
                  },
                };

                break;
              }
              case PostBlockType.CODE: {
                const codeBlock = block as CodeT;
                postViewBlock = {
                  component: CodeBlock,
                  data: {
                    type: codeBlock.type,
                    position: codeBlock.position,
                    language: codeBlock.language,
                    text: codeBlock.text,
                  },
                };

                break;
              }
              case PostBlockType.YOUTUBE: {
                const youtubeBlock = block as YoutubeT;
                postViewBlock = {
                  component: YoutubeBlock,
                  data: {
                    type: youtubeBlock.type,
                    position: youtubeBlock.position,
                    url: youtubeBlock.url,
                  },
                };

                break;
              }
            }
            return postViewBlock;
          }),
        };
      } else {
        return {
          blocks: [],
          createdAt: new Date(),
          title: "Post with this language is missing",
        };
      }
    },
  },
});
