
import { defineComponent, PropType } from "vue";
import { useAppStore } from "@/stores/AppStore";
import { Utils } from "@/utils/Utils";
import { PostT } from "../../../../shared/post/PostT";
import { Language } from "../../../../shared/Language";

export default defineComponent({
  name: "NewsItem",
  setup() {
    const appStore = useAppStore();
    return {
      appStore,
    };
  },
  computed: {
    Utils() {
      return Utils;
    },
  },
  props: {
    isMain: Boolean,
    post: {
      type: Object as PropType<PostT>,
    },
    language: String as PropType<Language>,
  },
  methods: {
    goToPost() {
      this.$router.push(`/post/${this.post?._id}`);
    },
  },
});
