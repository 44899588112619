import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_code_highlight = _resolveComponent("code-highlight")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.renderComponent)
      ? (_openBlock(), _createBlock(_component_code_highlight, {
          key: 0,
          language: _ctx.language
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.text), 1)
          ]),
          _: 1
        }, 8, ["language"]))
      : _createCommentVNode("", true)
  ]))
}