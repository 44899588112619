
import { defineComponent } from "vue";

export type ExperienceType = {
  type: string;
  date: string;
  intro: string;
};

export type SkillsType = {
  title: string;
  intro: string;
  skills: string[];
};

export default defineComponent({
  name: "AboutView",
  data(): {
    selectedIndex: number;
    aboutData: {
      title: string;
      info: ExperienceType[] | SkillsType[];
    }[];
  } {
    return {
      selectedIndex: 0,
      aboutData: [
        {
          title: "skills",
          info: [
            {
              title: "Backend Developer",
              intro:
                "In backend development, I learned new programming languages, frameworks, databases, and more. The list of technologies I worked with is as follows:",
              skills: [
                "Javascript",
                "Typescript",
                "ExpressJS",
                "Koa",
                "RabbitMQ",
                "Redis",
                "MongoDB",
                "ElasticSearch",
                "Angular",
                "Vue",
              ],
            },
            {
              title: "Automation Quality Assurance",
              intro:
                "While writing automated tests, I have acquired and absorbed my skills in the following technologies:",
              skills: [
                "Java",
                "Selenium",
                "Selenide",
                "TestNG",
                "MongoDB",
                "Redis",
              ],
            },
          ],
        },
        {
          title: "experience",
          info: [
            {
              type: "Master's degree of Computer Engineering",
              date: "2013-2019",
              intro:
                "I graduated with a Master's degree of Computer Engineering from Vinnytsia National Technical University",
            },
            {
              type: "Quality Assurance Automation Engineer",
              date: "2019-2021",
              intro:
                "For 2 years, I had been working at Temerix, one of the most friendly companies. My job was to develop automated tests",
            },
            {
              type: "Backend Developer",
              date: "2021",
              intro:
                "I always want something a little different. Therefore, in 2021, I changed my position and became a backend developer",
            },
          ],
        },
      ],
    };
  },
  methods: {
    setIndex(index: number) {
      this.selectedIndex = index;
    },
  },
});
