
import { defineComponent } from "vue";

export default defineComponent({
  name: "RoundTextSymbol",
  props: {
    symbol: String,
    turnDeg: Number
  }
});
