import { HttpClient } from "@/api/HttpClient";
import type { UserDTO } from "../../../shared/user/UserDTO";

export class UsersAPI extends HttpClient {
  protected endPoint = "/users";

  public async getCurrentUser(): Promise<UserDTO | null> {
    const userResponse = await this.get<UserDTO | null>("/current-user");
    return userResponse.data;
  }

  public async uploadUserImage(file: Blob): Promise<string> {
    const formData = new FormData();
    formData.append("file", file);
    const response = await this.post<string, FormData>(
      "/upload-user-image",
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    return response.data;
  }

  public async updateUser(user: UserDTO): Promise<string> {
    const response = await this.put<string, UserDTO>("/", user);
    return response.data;
  }
}
