import { HttpClient } from "@/api/HttpClient";
import type { PostBodyT, PostT } from "../../../shared/post/PostT";
import { Language } from "../../../shared/Language";

export class PostsAPI extends HttpClient {
  protected endPoint = "/posts";

  public async createPost(post: PostT): Promise<PostT> {
    return (await this.post<PostT, PostT>("", post)).data;
  }

  public async updatePost(post: PostT): Promise<PostT> {
    return (await this.put<PostT, PostT>("", post)).data;
  }

  public async getPostById(id: string): Promise<PostT> {
    const post = (await this.get<PostT>(`/${id}`)).data;
    for (const language of Object.keys(post.language)) {
      if (post.language[language as Language]) {
        const bodyForUpdate = post.language[language as Language] as PostBodyT;
        bodyForUpdate.createdAt = new Date(bodyForUpdate.createdAt);
      }
    }
    return post;
  }

  public async getAllPreviews(language: Language): Promise<PostT[]> {
    return (await this.get<PostT[]>(`/all-previews/${language}`)).data.map(
      (post) => {
        for (const language of Object.keys(post.language)) {
          if (post.language[language as Language]) {
            const bodyForUpdate = post.language[
              language as Language
            ] as PostBodyT;
            bodyForUpdate.createdAt = new Date(bodyForUpdate.createdAt);
          }
        }
        return post;
      }
    );
  }
}
