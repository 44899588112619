
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    src: String,
    width: String,
    height: String,
  },
});
