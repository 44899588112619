export class Utils {
  public static formatDate(date: Date) {
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  public static imagePath(url?: string, path?: string) {
    if (url) {
      try {
        url = require("@/assets/" + path + url);
      } catch (e) {
        url = "";
      }
    } else {
      url = require(`./../../public/images/KEKW.png`); // Default image.
    }
    return url;
  }
}
