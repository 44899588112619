import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogoIcon = _resolveComponent("LogoIcon")!
  const _component_Motion = _resolveComponent("Motion")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Motion, {
      class: "fixed top-0 bottom-0 h-screen w-screen z-30 bg-accentSecondary flex",
      initial: _ctx.transitionVariants.initial,
      animate: _ctx.transitionVariants.animate,
      transition: {
        duration: 0.5,
        easing: 'ease-in-out',
      }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_LogoIcon, {
          width: "50px",
          height: "50px",
          class: "mx-auto my-auto text-accent",
          "start-animation": true
        })
      ]),
      _: 1
    }, 8, ["initial", "animate"])
  ]))
}