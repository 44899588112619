import HomeView from "@/views/HomeView.vue";
import IconCommunity from "@/components/icons/IconCommunity.vue";
import AboutView from "@/views/AboutView.vue";
import NewsView from "@/views/NewsView.vue";
import PostView from "@/views/PostView.vue";
import IconHome from "@/components/icons/IconHome.vue";
import IconNews from "@/components/icons/IconNews.vue";
import IconAbout from "@/components/icons/IconAbout.vue";
import { Component } from "vue";
import IconProfile from "@/components/icons/IconProfile.vue";
import LoginView from "@/views/LoginView.vue";
import AccountView from "@/views/AccountView.vue";
import RegisterView from "@/views/RegisterView.vue";
import LoginByTokenView from "@/views/LoginByTokenView.vue";

export const Links: {
  name: string;
  path: string;
  icon: Component;
  component: Component;
  isNav: boolean;
}[] = [
  {
    name: "home",
    path: "/",
    icon: IconHome,
    component: HomeView,
    isNav: true,
  },
  {
    name: "news",
    path: "/news",
    icon: IconNews,
    component: NewsView,
    isNav: true,
  },
  {
    name: "about",
    path: "/about",
    icon: IconAbout,
    component: AboutView,
    isNav: true,
  },
  {
    name: "login",
    path: "/login",
    icon: IconProfile,
    component: LoginView,
    isNav: false,
  },
  {
    name: "register",
    path: "/register",
    icon: IconProfile,
    component: RegisterView,
    isNav: false,
  },
  {
    name: "account",
    path: "/account",
    icon: IconProfile,
    component: AccountView,
    isNav: false,
  },
  {
    name: "post",
    path: "/post/:id",
    icon: IconCommunity,
    component: PostView,
    isNav: false,
  },
  {
    name: "login-by-token",
    path: "/login-by-token/:token",
    icon: IconCommunity,
    component: LoginByTokenView,
    isNav: false,
  },
];
