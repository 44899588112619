
import { defineComponent } from "vue";
import {useUserStore} from "@/stores/UserStore";

export default defineComponent({
  name: "LoginByTokenView",
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  async created() {
    const token = this.$route.params.token as string;
    if (token) {
      localStorage.setItem("token", token);
      await this.userStore.getCurrentUser();
    }

    this.$router.push("/");
  },
});
