import { defineStore } from "pinia";
import { Language } from "../../../shared/Language";

export type AppStore = {
  isUserLoading: boolean;
  selectedLanguage: Language;
};

export const useAppStore = defineStore("app", {
  state: (): AppStore => {
    return {
      isUserLoading: false,
      selectedLanguage: Language.ENGLISH,
    };
  },
  actions: {
    userLoading() {
      this.isUserLoading = true;
    },
    userLoaded() {
      this.isUserLoading = false;
    },
    selectLanguage(language: Language) {
      this.selectedLanguage = language;
    },
  }
});
