
import { defineComponent } from "vue";
import { Motion } from "motion/vue";
import LogoIcon from "@/components/icons/LogoIcon.vue";

export default defineComponent({
  name: "PageTransitionEnd",
  components: { LogoIcon, Motion },
  data() {
    return {
      transitionVariants: {
        initial: {
          height: "100%",
        },
        animate: {
          height: ["100%", "0%"],
        },
      },
    };
  },
});
